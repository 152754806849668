import React, { ReactNode, useRef } from 'react'

import { Modal } from 'inter-site-components'
import YoutubeVideo from 'components/YoutubeVideo'

type OpenVideoProps = {
  link: string;
  children: ReactNode;
  onClick: Function;
}

type ModalProps = {
  close: () => void;
  open: () => void;
}

function VideoModal ({ children, link, onClick }: OpenVideoProps) {
  const modalRef = useRef<ModalProps>(null)

  function handleClose () {
    modalRef.current && modalRef.current.close()
  }

  function handleOpen () {
    modalRef.current && modalRef.current.open()
    if (onClick) {
      onClick()
    }
  }

  return (
    <>
      <div onClick={handleOpen}>
        {children}
      </div>
      <Modal ref={modalRef}>
        <div className='modal'>
          <div className='backdrop' onClick={handleClose} />
          <div className='content'>
            <div className='close-button' onClick={handleClose}>X</div>
            <YoutubeVideo
              src={link}
              width='100%'
              height='100%'
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

VideoModal.defaultProps = {
  link: '',
  children: <></>,
}

export default VideoModal
