import React from 'react'
import RadioButtonOn from 'inter-frontend-svgs/lib/v2/toggle/radio-button-on'
import RadioButtonOff from 'inter-frontend-svgs/lib/v2/toggle/radio-button-off'

// Data
import FundsJSON from '../../funds.json'

// Types
import { Fund, Values } from '../../types'

// Style
import { OptionCard } from '../../style'

type SelectYourFundProps = {
  values?: Values;
  setValues: (value: Values) => void;
  setStep: (value: string) => void;
}

const SelectYourFund = ({ values, setValues, setStep }: SelectYourFundProps) => {
  function setFund (fund: Fund) {
    setValues({
      ...values,
      fund,
    })
  }

  return (
    <div className='row justify-content-between mt-3 mt-md-5'>
      <div className='col-12 col-lg-5'>
        <header className='pb-md-3'>
          <h4 className='font-sora fw-600 fs-20 lh-25 text-grayscale--400 my-2 mt-md-0 mb-md-4 fs-md-32 lh-md-40'>
            <small className='fs-14 lh-17 fs-md-20 lh-md-25'>04.</small> Selecione seu fundo
          </h4>
        </header>
        <p className='fs-16 lh-19 text-grayscale--400'>
          Você quer investir seu dinheiro em um fundo mais arrojado, com riscos e expectativas de retorno maiores, ou em fundos mais conservadores, com menores riscos e retornos mais moderados?
        </p>
        <p className='fs-16 lh-19 text-grayscale--400 mb-4'>
          Aqui no Inter, você pode escolher entre fundos de todos os tipos. E pra simplificar sua vida, classificamos cada tipo com uma cor diferente no nosso app.
        </p>
        <button
          disabled={!values?.fund}
          className='btn bg-orange--extra text-white text-none rounded-3 fw-600 fs-14 lh-17 mt-3 d-none d-lg-block'
          title='Ver resultado'
          onClick={() => setStep('result-chart')}
        >
          Ver resultado
        </button>
      </div>
      <div className='col-12 col-lg-7 col-xl-6'>
        {
          FundsJSON.map((fund: Fund) => {
            const selected = values?.fund?.id === fund.id
            return (
              <OptionCard key={fund.id} onClick={() => setFund(fund)} color={fund.color} selected={selected} className='d-flex py-4 px-3 mb-3 bg-grayscale--100'>
                {
                  selected
                  ? <RadioButtonOn className='flex-shrink-0' width='32' height='32' />
                  : <RadioButtonOff className='flex-shrink-0' width='32' height='32' />
                }
                <div className='ml-2 ml-md-4'>
                  <h5 className='font-sora fw-600 fs-20 lh-25 mb-2 fs-md-24 lh-md-30'>{fund.name}</h5>
                  <p className='fs-16 lh-20 text-grayscale--400' dangerouslySetInnerHTML={{ __html: fund.description }} />
                </div>
              </OptionCard>
            )
          })
        }
        <button
          disabled={!values?.fund}
          className='btn bg-orange--extra text-white text-none rounded-3 fw-600 fs-14 lh-17 mt-4 mx-auto d-block d-lg-none mt-md-5'
          title='Ver resultado'
          onClick={() => setStep('result-chart')}
        >
          Ver resultado
        </button>
      </div>
    </div>
  )
}

export default SelectYourFund
