import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  position: relative;

  .recharts-responsive-container {
    max-width: 800px;
    margin: 0 auto;

    .y-axis {
      transform: translateX(35px);

      @media (min-width: ${breakpoints.md}) {
        transform: translateX(0);
      }
    }

    svg {
      overflow: initial;
    }
  }

  .final-tooltip {
    position: absolute;
    top: -30px;
    right: 0;
  }
`

export const CustomTooltipWrapper = styled.div`
  background-color: ${grayscale[100]};
  padding: 16px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(75, 78, 92, 0.24);
  max-width: 220px;
`
