import React from 'react'
import { Utility } from 'inter-site-components'
import { TooltipProps } from 'recharts'

// Tyés
import { Fund } from '../../types'

// Style
import { CustomTooltipWrapper } from './style'

type CustomTooltipProps = {
  fund: Fund;
} & TooltipProps

function CustomTooltip ({ active, payload, fund }: CustomTooltipProps) {
  if (active && (payload && payload.length > 0)) {
    return (
      <CustomTooltipWrapper className='py-2 px-3 rounded-3 shadow-sm'>
        <p className='fw-700 fs-14 lh-17 text-gray--800 mb-1 text-left'>R$ {Utility.numberToReal(payload[0].value)} acumulado</p>
        <p className='fs-14 lh-17 text-gray--800 mb-0 text-left'>Investindo em {fund.name} com {fund.rate * 100}% de rentabilidade anual</p>
      </CustomTooltipWrapper>
    )
  }

  return null
}
export default CustomTooltip
