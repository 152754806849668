/* eslint-disable @typescript-eslint/typedef */
import React, { useState } from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import { black, white } from 'src/styles/colors'
import { IconDiv } from './style'
import usePageQuery from '../../pageQuery'
import Img from 'gatsby-image'
import data from './assets/data.json'

interface ICarouselData {
  title: string;
  description: string;
}

const CarouselData: ICarouselData[] = data

const icons = [
  'help',
  'add',
  'up-stairs',
  'agenda',
]

const ComoFunciona = () => {
  const [ carouselIndex, setCarouselIndex ] = useState(0)

  const data = usePageQuery()

  const handleClickIcon = (index: number) => {
    setCarouselIndex(index)
  }

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 pb-4 pb-md-0'>
            <Img fluid={data.mulherCariciandoGatoCafe.fluid} alt='' />
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 pb-3'>Como funciona a previdência privada?</h2>
            <div className='d-flex align-items-center justify-content-between'>
              {icons.map((icon, index: number) => (
                <IconDiv key={icon} isActive={carouselIndex === index} onClick={() => handleClickIcon(index)}>
                  <OrangeIcon icon={icon} size='MD' color={carouselIndex === index ? white : black} />
                </IconDiv>
              ))}
            </div>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              goToSlide={carouselIndex}
              beforeChange={(state) => setCarouselIndex(state)}
            >
              {CarouselData.map((item: ICarouselData, index: number) => {
                return (
                  <div key={index}>
                    <h3 className='fs-18 lh-22 fs-lg-18 lh-lg-22 fw-600'>{item.title}</h3>
                    <p className='fs-14 lh-17 fs-xl-16 lh-xl-19 text-grayscale--400'>{item.description}</p>
                  </div>
                )
              })}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComoFunciona
