import React from 'react'
import Img from 'gatsby-image'
import RadioButtonOn from 'inter-frontend-svgs/lib/v2/toggle/radio-button-on'
import RadioButtonOff from 'inter-frontend-svgs/lib/v2/toggle/radio-button-off'

// Types
import { DataImage, Values } from '../../types'

// Style
import { OptionCard } from '../../style'

type SetTaxationProps = {
  values?: Values;
  setValues: (value: Values) => void;
  setStep: (value: string) => void;
  data: DataImage;
}

const SetTaxation = ({ values, setValues, setStep, data }: SetTaxationProps) => {
  function setTaxation (taxation: string) {
    setValues({
      ...values,
      taxation,
    })
    setTimeout(() => {
      setStep('select-your-fund')
    }, 1000)
  }

  return (
    <>
      <div className='row align-items-end justify-content-between'>
        <div className='col-12 col-xl-7'>
          <header>
            <h4 className='font-sora fw-600 fs-20 lh-25 text-grayscale--400 my-2 mt-md-4 fs-md-32 lh-md-40'>
              <small className='fs-14 lh-17 fs-md-20 lh-md-25'>03.</small> Defina a tributação
            </h4>
            <p className='fs-16 lh-19 text-grayscale--400 mb-4'>Importante saber: É possível mudar da tabela Progressiva para a Regressiva, mas não o contrário. </p>
          </header>
          <OptionCard onClick={() => setTaxation('tabela-regressiva')} selected={values?.taxation === 'tabela-regressiva'} className='d-flex py-4 px-3 mb-3 bg-grayscale--100'>
            {
              values?.taxation === 'tabela-regressiva'
              ? <RadioButtonOn className='flex-shrink-0' width='32' height='32' />
              : <RadioButtonOff className='flex-shrink-0' width='32' height='32' />
            }
            <div className='ml-4'>
              <h5 className='font-sora fw-600 fs-20 lh-25 mb-2'>Tabela Regressiva</h5>
              <p className='fs-16 lh-19 text-grayscale--400'>Precisa do dinheiro só lá no futuro, no longo prazo? A Regressiva é a melhor opção. O desconto sobre os rendimentos começa em 35% e vai diminuindo ao longo do tempo, até chegar em 10%.</p>
            </div>
          </OptionCard>
          <OptionCard onClick={() => setTaxation('tabela-progressiva')} selected={values?.taxation === 'tabela-progressiva'} className='d-flex py-4 px-3 bg-grayscale--100'>
            {
              values?.taxation === 'tabela-progressiva'
              ? <RadioButtonOn className='flex-shrink-0' width='32' height='32' />
              : <RadioButtonOff className='flex-shrink-0' width='32' height='32' />
            }
            <div className='ml-4'>
              <h5 className='font-sora fw-600 fs-20 lh-25 mb-2'>Tabela Progressiva</h5>
              <p className='fs-16 lh-19 text-grayscale--400'>Pra quem ainda não sabe se precisará resgatar o valor em pouco tempo. O desconto é baseado na tabela progressiva do IR. Vai desde a isenção até 27,5%, dependendo do valor do resgate.</p>
            </div>
          </OptionCard>
        </div>
        <div className='d-none d-xl-block col-xl-4'>
          <Img fluid={data.definaATributacao.fluid} />
        </div>
      </div>
    </>
  )
}

export default SetTaxation
