import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { parseISO, format } from 'date-fns'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Icon from 'src/components/UI/MarkdownIcon'

import BlogPosts from '../../assets/data/blogPosts.json'

type BlogPosts = {
  image: string;
  title: string;
  introduction: string;
  slug: string;
  publishDate: string;
  category: {
    name: string;
    slug: string;
  };
  subCategory: {
    name?: string;
    slug?: string;
  };
}

const SaibaMaisSobre = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const link = 'https://blog.inter.co'

  return (
    <section id='saiba-mais-sobre-a-previdencia-privada' className='bg-white d-flex pb-md-5 pb-xl-0'>
      <div className='container py-5'>
        <header>
          <h2 className='font-sora fw-600 fs-24 lh-30 text-grayscale--500 mb-3 text-md-center fs-md-40 lh-md-50 mb-lg-4'>
            Os melhores conteúdos sobre investimentos
          </h2>
          <p className='fs-14 text-grayscale--400 lh-17 fs-md-18 lh-md-22 text-md-center mb-0 mb-lg-4'>
            Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
          </p>
        </header>
        <DefaultCarousel
          containerClass='mx-md-n2'
          sm={{ items: 1 }}
          md={{ items: 2 }}
          lg={{ items: 3 }}
          xl={{ items: 3 }}
        >
          {
            BlogPosts.map((item: BlogPosts, index: number) => (
              <a
                key={index} href={`https://blog.inter.co/${item.slug}`} target='_blank'
                rel='noreferrer' className='text-decoration-none mx-2 d-flex flex-column h-100'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_08',
                    element_action: 'click button',
                    element_name: 'Leia mais',
                    section_name: 'Saiba mais sobre previdência privada',
                    redirect_url: item.slug,
                  })
                }}
              >
                <figure className='m-0'>
                  <img src={item.image} alt='' />
                </figure>
                <div
                  className='bg-white position-relative py-3 px-4 mt-n4 rounded-top-left-5 rounded-top-right-5 flex-grow-1'
                >
                  <div className='d-flex flex-wrap align-items-center'>
                    <span
                      className='fs-12 lh-15 fw-700 rounded-2 bg-orange--extra text-white py-1 px-2 mr-3 mb-1 mb-md-0'
                    >{item.category.name}
                    </span>
                    {item.subCategory.name &&
                      <span
                        className='fs-12 lh-15 fw-700 rounded-2 bg-grayscale--100 text-orange--extra py-1 px-2 mr-3'
                      >{item.subCategory.name}
                      </span>}
                  </div>
                  <h3 className='fs-16 lh-20 lh-lg-25 fs-xl-20 fw-600 text-grayscale--500 mt-3 mb-2'>
                    {item.title}
                  </h3>
                  <span className='fs-12 lh-15 fw-700 text-grayscale--300 d-block mb-2 mb-md-3'>
                    <time dateTime={item.publishDate}>{format(parseISO(item.publishDate), 'dd/MM/yyyy')}</time>
                  </span>
                  <p className='fs-14 lh-17 fs-xl-16 lh-xl-20 text-grayscale--400'>
                    {item.introduction}
                  </p>
                  <span className='fs-14 text-orange--base d-none d-lg-block text-lg-right'>
                    Leia mais
                    <Icon
                      width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right'
                      directory='v2'
                    />
                  </span>
                </div>
              </a>
            ))
          }
        </DefaultCarousel>
      </div>
    </section>
  )
}

export default SaibaMaisSobre
