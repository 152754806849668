import React from 'react'

import { TabCategory, CardFund, FooterFunds, Values, Borderline } from './style'
import data from './assets/data.json'

export enum TaxValue {
  BAIXO = 0,
  MODERADO = 1,
  ALTO = 2
}

interface IDataFunds {
  type: number;
  type_title: string;
  description: string;
  redimento_medio: string;
}

const dataFunds: IDataFunds[] = data

const MaisOpcoes = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h2 className='fs-24 lh-30 fs-md-40 lh-md-50 fw-600 text-grayscale--500 text-left text-md-center'>Várias opções de fundos pra você</h2>
            <p className='fs-18 lh-22 text-grayscale--400 fw-400 pt-2 text-left text-md-center'>Temos diversas opções disponíveis para você escolher e planejar seus próximos passos.</p>
          </div>
          <div className='row justify-content-center align-items-center'>
            {dataFunds.map((item: IDataFunds, index: number) => (
              <div className='col-12 col-md-6 col-lg-5 col-xl-4 mb-5 mb-xl-4' key={index}>
                <div>
                  <TabCategory status={item.type}>{item.type_title}</TabCategory>
                  <CardFund>
                    <div className='category-info'>
                      <Borderline status={item.type} />
                      <p className='fs-18 lh-22 text-grayscale--400 mb-0'>{item.description}</p>
                    </div>
                  </CardFund>

                  <FooterFunds>
                    <Values>
                      <p className='fs-12 lh-15 fw-400 mb-1 text-grayscale--400'>Rendimento médio anual entre</p>
                      <span className='font-sora fw-600 fs-16 lh-20 text-gratscale--400'>{item.redimento_medio}</span>
                    </Values>
                  </FooterFunds>
                </div>
              </div>
              ))}
          </div>
          <div className='col-12'>
            <p className='fs-14 lh-17 text-grayscale--400 text-md-center'>As rentabilidades acima são suscetíveis a variações e os redimentos passados não são garantias de rendimentos futuros.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MaisOpcoes
