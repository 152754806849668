import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

interface IVideoDiv {
  bgImage: string;
}

export const OpenVideoButton = styled.button`
  position: absolute;
  left: 50%;
  bottom: 75px;
  transform: translateX(-50%);
  z-index: 1;

  &:focus {
    outline: none;
  }

  @media ${device.tablet} {
    bottom: auto;
    top: 50%;
    left: 80%;
    transform: translate(-35%, -50%);
  }

  @media ${device.desktopXL} {
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const VideoDiv = styled.div<IVideoDiv>`
  height: 276px;
  width: 100%;
  background-image: url(${(props: IVideoDiv) => props.bgImage});
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  
  .icon {
    position: absolute;
    top: 14px;
    left: 14px;
  }

  &:hover {
    cursor: pointer;
  }
  
  @media ${device.tablet} {
    height: 220px;
    width: 100%;
    max-width: 279px;
  }
`

export const Shadow = styled.div`
  height: 276px;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;

  h4 {
    margin-top: 100px;
  }
    
  @media ${device.tablet} {
    height: 220px;
    width: 100%;
    max-width: 279px;
  }
`
