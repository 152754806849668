import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CustomSection = styled.section`
  min-height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  
  h1 {
    font-size: 24px;
    line-height: 30px;
  }

  img {
    max-width: 100%;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    min-height: 450px;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);

    h1 {
      font-size: 48px;
      line-height: 60px;
    }
  }

  @media ${device.desktopXL} {
    h1 {
      font-size: 40px;
      line-height: 50px;
    }

    img {
      float: right;
      max-width: 456px;
    }
  }

  @media ${device.desktopXXXL} {
    margin-top: 90px;
    min-height: 658px;
    
    h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }
`
