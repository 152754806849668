import React from 'react'
import FAQ from 'src/components/StructuredData/FAQ'
import pageContext from '../../pageContext.json'

const Faq = () => {
  return (
    <section id='perguntas-frequentes' className='bg-white pt-5 pb-4 pb-md-5'>
      <div className='container'>
        <h3 className='fs-28 fs-md-42 text-md-center'>Perguntas frequentes</h3>
        <div className='row'>
          <FAQ
            columns={{
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
            }} data={pageContext.structuredData.faq}
          />
        </div>
      </div>
    </section>
  )
}

export default Faq
