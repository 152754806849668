import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IJaTemUmPlanoProps {
  onOpenModal: () => void;
}

const JaTemUmPlano = ({ onOpenModal }: IJaTemUmPlanoProps) => {
  const dataImage = usePageQuery()
  const windowWidth = useWidth(200)
  const isMobile = windowWidth < WIDTH_MD
  const [ sendDatalayerEvent ] = useDataLayer()

  const link = 'https://intergo.app/d0db4c8f'

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-5 order-md-2 order-xl-1 pb-4 pb-md-0'>
            <Img fluid={dataImage.jaTemUmPlano.fluid} alt='Imagem com plano de previdencia' />
          </div>
          <div className='col-12 col-md-6 col-xl-4'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-grayscale--500 pb-2'>Já tem um plano de previdência privada em outra instituição? Traga ela pro Inter!</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 pb-2'>Você conta com uma equipe especializada para oferecer a melhor experiência para você, em uma previdência cada vez mais completa.</p>
            {isMobile
              ? <a
                  href='https://intergo.app/d0db4c8f'
                  className='btn btn--lg bg-orange--extra my-2 text-white rounded-3 text-none fw-700 fs-14 lh-17'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      element_name: 'Trazer minha previdência para o Inter',
                      section_name: 'Já tem um plano de previdência privada em outra instituição? Traga ela pro Inter!',
                      redirect_url: link,
                    })
                  }}
                >
                Trazer minha previdência para o Inter
                </a>
            : <button
                className='btn btn--lg bg-orange--extra my-2 text-white rounded-3 text-none fw-700 fs-14 lh-17'
                onClick={() => {
                onOpenModal()
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Trazer minha previdência para o Inter',
                  section_name: 'Já tem um plano de previdência privada em outra instituição? Traga ela pro Inter!',
                  redirect_url: link,
                })
                }}
              >
              Trazer minha previdência para o Inter
            </button>
            }

          </div>
        </div>
      </div>
    </section>
  )
}

export default JaTemUmPlano
