import React from 'react'
import Img from 'gatsby-image'
import RadioButtonOn from 'inter-frontend-svgs/lib/v2/toggle/radio-button-on'
import RadioButtonOff from 'inter-frontend-svgs/lib/v2/toggle/radio-button-off'

// Types
import { DataImage, Values } from '../../types'

// Style
import { OptionCard } from '../../style'

type ChooseModeProps = {
  values?: Values;
  setValues: (value: Values) => void;
  setStep: (value: string) => void;
  data: DataImage;
}

const ChooseMode = ({ values, setValues, setStep, data }: ChooseModeProps) => {
  function setMode (mode: string) {
    setValues({
      ...values,
      mode,
    })
    setTimeout(() => {
      setStep('enter-your-numbers')
    }, 1000)
  }

  return (
    <>
      <header>
        <h4 className='font-sora fw-600 fs-20 lh-25 text-grayscale--400 my-2 my-md-4 fs-md-32 lh-md-40'>
          <small className='fs-14 lh-17 fs-md-20 lh-md-25'>01.</small> Escolha a modalidade
        </h4>
      </header>
      <div className='row justify-content-between align-items-end'>
        <div className='col-12 col-xl-7'>
          <OptionCard onClick={() => setMode('pgbl')} selected={values?.mode === 'pgbl'} className='d-flex py-4 px-3 mb-3 bg-grayscale--100'>
            {
              values?.mode === 'pgbl'
              ? <RadioButtonOn className='flex-shrink-0' width='32' height='32' />
              : <RadioButtonOff className='flex-shrink-0' width='32' height='32' />
            }
            <div className='ml-4'>
              <h5 className='font-sora fw-600 fs-20 lh-25 mb-2'>PGBL</h5>
              <p className='fs-16 lh-19 text-grayscale--400'>Ideal pra quem quer investir até 12% do total da sua renda na previdência. <strong>Com desconto no Imposto de Renda (IR)</strong>, o que você investiu na previdência (até 12%), é abatido da base de cálculo.</p>
              <p className='fs-16 lh-19 text-grayscale--400'><strong>Economize no Imposto de Renda.</strong> Com uma renda anual de R$ 100.000, você economiza até R$ 3.300.</p>
            </div>
          </OptionCard>
          <OptionCard onClick={() => setMode('vgbl')} selected={values?.mode === 'vgbl'} className='d-flex py-4 px-3 bg-grayscale--100'>
            {
              values?.mode === 'vgbl'
              ? <RadioButtonOn className='flex-shrink-0' width='32' height='32' />
              : <RadioButtonOff className='flex-shrink-0' width='32' height='32' />
            }
            <div className='ml-4'>
              <h5 className='font-sora fw-600 fs-20 lh-25 mb-2'>VGBL</h5>
              <p className='fs-16 lh-19 text-grayscale--400'>Ideal pra quem quer investir <strong>mais</strong> do que 12% do total da sua renda na previdência. O IR incide apenas sobre seus rendimentos, e só quando você resgata, lá na frente.</p>
            </div>
          </OptionCard>
        </div>
        <div className='d-none d-xl-block col-xl-4'>
          <Img className='ml-auto' fluid={data.escolhaAModalidade.fluid} />
        </div>
      </div>
    </>
  )
}

export default ChooseMode
