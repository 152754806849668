import React from 'react'
import { Utility } from 'inter-site-components'
import { RadarPoint } from 'recharts'

const CustomYAxisTick = ({ x, y, payload }: RadarPoint) => {
  return (
    <g className='y-axis'>
      <text x={-x} y={y - 10} dy={15} fontSize={12} fill='#6A6C72'>
        R$ {Utility.numberToReal(payload.value)}
      </text>
    </g>
  )
}

export default CustomYAxisTick
