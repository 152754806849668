import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDomReady from 'src/hooks/window/useDomReady'
import QrModalPortabilidade from './components/QrModal/_QrModal'
import QrModalAporte from './components/QrModalAporte/_QrModal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

import Hero from './sections/hero/_index'
import ComoFunciona from './sections/comoFunciona/_index'
import JaTemUmPlano from './sections/jaTemUmPlano/_index'
import PrivatePensionSimulator from 'src/components/UI/Simulators/PrivatePensionSimulator'
import ConfiraNossaSerie from './sections/confiraNossaSerie/_index'
import InvistaQuandoQuiser from './sections/invistaQuandoQuiser/_index'
import MaisOpcoes from './sections/maisOpcoes/_index'
import SaibaMaisSobre from './sections/saibaMaisSobre/_index'
import Faq from './sections/faq/_index'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'

function PrevidenciaPrivada () {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openAporteModal, setOpenAporteModal ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_09',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const openPortabilidadeModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <QrModalPortabilidade closeModal={() => setOpenModal(false)} />
    </Modal>
  )

  const openQrAporteModal = domReady && (
    <Modal isModalOpen={openAporteModal} setIsModalOpen={setOpenAporteModal} locationToRender={document.body}>
      <QrModalAporte closeModal={() => setOpenModal(false)} />
    </Modal>
  )

  const handleOpenModal = () => {
    setOpenModal(!openModal)
  }

  const handleOpenQrAporteModal = () => {
    setOpenAporteModal(!openAporteModal)
  }

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <ComoFunciona />
        <JaTemUmPlano onOpenModal={handleOpenModal} />
        <PrivatePensionSimulator />
        <ConfiraNossaSerie />
        <InvistaQuandoQuiser onOpenModal={handleOpenQrAporteModal} />
        <MaisOpcoes />
        <SaibaMaisSobre />
        <Faq />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          dataLayer={dataLayer}
          section='dobra_10'
        />
        {openPortabilidadeModal}
        {openQrAporteModal}
      </Layout>
    </Wrapper>
  )
}

export default PrevidenciaPrivada
