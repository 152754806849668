import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerMobile: imageSharp(fluid: {originalName: { regex: "/previdencia-privada-banner-mobile/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      homemPrevPrivadaHero: imageSharp(fluid: {originalName: { regex: "/pessoa_prev_hero/" }}) {
        fluid(maxWidth: 475, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      homemPrevPrivadaHeroMd: imageSharp(fluid: {originalName: { regex: "/pessoa-prev-hero-md/" }}) {
        fluid(maxWidth: 475, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }      
      mulherCariciandoGatoCafe: imageSharp(fluid: {originalName: { regex: "/mulher-acariciando-gato-cafe/" }}) {
        fluid(maxWidth: 475, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      jaTemUmPlano: imageSharp(fluid: {originalName: { regex: "/ja-tem-um-plano/" }}) {
        fluid(maxWidth: 571, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blogOrangeFriday: imageSharp(fluid: {originalName: { regex: "/previdencia-privada-blog-orange-friday/" }}) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      blogDesvendando: imageSharp(fluid: {originalName: { regex: "/previdencia-privada-blog-desvendando/" }}) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      blogBeneficioFiscal: imageSharp(fluid: {originalName: { regex: "/previdencia-privada-blog-beneficio-fiscal/" }}) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
