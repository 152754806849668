import React from 'react'

// Images
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import QrCodePortabilidade from './assets/qr-dobra-3-previdencia.png'

// Styles
import { Container, CloseButton } from './style'

type ModalProps = {
  closeModal: () => void;
}

function QrModalPortabilidade ({ closeModal }: ModalProps) {
  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-3' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <Container isModal={closeModal} className='d-flex align-items-center under-age'>
      {closeButton}
      <div className='col-12 px-md-0'>
        <h3 className='fs-24 lh-30 text-grayscale--500 fw-400 fw-md-600'>
          Agora <span className='text-orange--extra'>abra nosso app</span> e <span className='d-block'>peça a sua portabilidade.</span>
        </h3>
        <div className='col-12 pb-4 pt-md-4 mx-auto d-none d-md-block text-center'>
          <div className='pr-md-5 pr-lg-0'>
            <img src={QrCodePortabilidade} alt='Acesse o App Inter' />
          </div>
        </div>
        <div className='col-12 d-none d-md-block px-0'>
          <hr />
          <p className='fs-14 lh-17 text-grayscale--400'>
            Ligue a câmera do seu celular e aponte para o QRcode acima. Você será redirecionado para a loja de aplicativos. Aí é só baixar o Super App.
          </p>
        </div>
      </div>
    </Container>
  )
}

export default QrModalPortabilidade
