import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { VideoDiv, Shadow } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'
import VideoModal from '../../components/VideoModal/_VideoModal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import VideoData from '../../assets/data/videos.json'

interface IVideoData {
  title: string;
  url: string;
  thumbnail: string;
}

const ConfiraNossaSerie = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='bg-grayscale--100'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 pb-3'>
              Confira nossa série de vídeos sobre a previdência
            </h2>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400'>
              Para contextualizar um pouco mais sobre o que é previdência privada e se ela a melhor opção para você, montamos uma série de videos explicativos para que possamos esclarecer todas suas dúvidas{' '}
              <a
                href='https://inter.co/pra-voce/investimentos/'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'sobre investimentos',
                    section_name: 'Confira nossa série de vídeos sobre a previdência',
                    redirect_url: 'https://inter.co/pra-voce/investimentos/',
                  })
                }}
              >
                <span className='text-orange--extra fw-600'>sobre investimentos</span>
              </a>.
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5 col-xl-5'>
            <DefaultCarousel
              containerClass='carousel-private-pension-funds'
              sm={{
                    items: 1,
              }}
              md={{ items: 1, partialVisibilityGutter: 40 }}
              lg={{ items: 1, partialVisibilityGutter: 80 }}
              xl={{ items: 2, partialVisibilityGutter: 0 }}
            >
              {
                VideoData.map((item: IVideoData, index: number) => (
                  <VideoDiv key={item.title} className='bg-white rounded-3' bgImage={item.thumbnail}>
                    <VideoModal
                      link={item.url}
                      onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        element_action: 'click button',
                        element_name: 'sobre investimentos',
                        section_name: item.title,
                        redirect_url: item.url,
                      })
                      }}
                    >
                      <OrangeIcon className='icon' icon='play' size='MD' color={orange.extra} />
                      <Shadow className='p-3 rounded-3'>
                        <div>
                          <h4 className='font-sora fw-600 fs-16 lh-20 text-white mb-0 pb-0'>{item.title}</h4>
                          <p className='fs-14 lh-17 text-white pt-2'>Video {index + 1}</p>
                        </div>
                      </Shadow>
                    </VideoModal>
                  </VideoDiv>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ConfiraNossaSerie
