import { orange } from 'src/styles/colors'
import styled, { css } from 'styled-components'

interface IIconProps {
  isActive: boolean;
}

export const IconDiv = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  ${(props: IIconProps) => props.isActive && css`
    background: ${orange.extra};
  `}

  &:not(:first-child) {
    margin-left: 16px;
  }
`
