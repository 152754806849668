import React, { useEffect, useState, useRef } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Steps
import ChooseMode from './Steps/ChooseMode'
import EnterYourNumbers from './Steps/EnterYourNumbers'
import SetTaxation from './Steps/SetTaxation'
import SelectYourFund from './Steps/SelectYourFund'
import ResultChart from './Steps/ResultChart'

// Types
import { Values } from './types'

// Hooks
import useQuery from './query'
import useWidth from 'src/hooks/window/useWidth'

// Style
import { Steps } from './style'

const WIDTH_LG = 768

function getStep (text: string) {
   switch (text) {
    case 'choose-mode':
      return '1'
    case 'enter-your-numbers':
       return '2'
    case 'set-taxation':
      return '3'
    case 'select-your-fund':
      return '4'
    case 'result-chart':
      return '5'
    default:
      return '1'
   }
}

const PrivatePensionSimulator: React.FC = () => {
  const data = useQuery()
  const windowWidth = useWidth(500)
  const sectionRef = useRef()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ step, setStep ] = useState('choose-mode')
  const [ values, setValues ] = useState<Values>()
  const steps = [ 'choose-mode', 'enter-your-numbers', 'set-taxation', 'select-your-fund' ]
  const childrenProps = { values, setValues, setStep, data }
  let content = <></>
  let title = 'Simule e Calcule o seu futuro'
  let description = 'Mostramos sua projeção a partir do que você escolher aqui e do quanto quiser investir'

  switch (step) {
    case 'choose-mode':
      content = <ChooseMode {...childrenProps} />
      break
    case 'enter-your-numbers':
      content = <EnterYourNumbers {...childrenProps} />
      break
    case 'set-taxation':
      content = <SetTaxation {...childrenProps} />
      break
    case 'select-your-fund':
      content = <SelectYourFund {...childrenProps} />
      break
    case 'result-chart':
        title = 'Veja os números dessas escolhas '
        description = 'Esse é a projeção dessa combinação em seu Imposto de Renda ao longo dos anos'
        content = <ResultChart {...childrenProps} />
      break
  }

  function goToStep (step: string, selected: string) {
    if (selected) {
      setStep(step)
      sendDatalayerEvent({
        section: 'dobra_04',
        element_action: 'click button',
        element_name: title,
        section_name: 'null',
        step: getStep(step),
      })
    }
  }

  useEffect(() => {
    if (windowWidth < WIDTH_LG && step === 'result-chart') {
      window.scrollTo({
        top: sectionRef.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }, [ step ])

  return (
    <section ref={sectionRef} id='calcule-seu-futuro' className='bg-white py-4 py-md-5'>
      <div className='container'>
        <header className='col-12 col-xl-9 mx-auto px-0'>
          <h3 className='font-sora fw-600 fs-24 lh-30 text-grayscale--500 mb-3 text-md-center fs-md-40 lh-md-50 mb-lg-4'>{title}</h3>
          <p className='fs-14 text-grayscale--400 lh-17 mb-4 fs-md-18 lh-md-22 text-md-center mb-lg-5'>{description}</p>
        </header>
        {
          step !== 'result-chart' && (
            <Steps className='mt-3 mb-4 d-flex justify-content-center'>
              <ul className='list-unstyled d-inline-flex align-items-center py-3'>
                {
                  steps.map((item: string, index: number) => {
                    const active = item === step ? 'active' : ''
                    const selected = steps.indexOf(step) > steps.indexOf(item) ? 'selected' : ''
                    return <li onClick={() => goToStep(steps[index], selected)} key={item} className={`text-center mx-2 position-relative ${active} ${selected}`}>{index + 1}</li>
                  })
                }
              </ul>
            </Steps>
          )
        }
        {content}
      </div>
    </section>
  )
}

export default PrivatePensionSimulator
