import { useStaticQuery, graphql } from 'gatsby'

const Query = () => {
  const query = useStaticQuery(graphql`
    query {
      escolhaAModalidade: imageSharp(fluid: {originalName: { regex: "/previdencia-privada-escolha-a-modalidade/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      informeSeusNumeros: imageSharp(fluid: {originalName: { regex: "/previdencia-privada-informe-seus-numeros/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      definaATributacao: imageSharp(fluid: {originalName: { regex: "/previdencia-privada-defina-a-tributacao/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return query
}

export default Query
