import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import BgSmall from '../../assets/images/background-opcoes-sm.png'
import BgMD from '../../assets/images/background-opcoes-md.png'
import BgLg from '../../assets/images/background-opcoes-lg.png'
import BgXl from '../../assets/images/background-opcoes-xl.png'

export const CustomSection = styled.section`
  height: 600px;
  background-image: url(${BgSmall});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: flex-end;

  @media ${device.tablet} {
    height: 544px;
    display: flex;
    align-items: center;
    background-image: url(${BgMD});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media ${device.desktopLG} {
    background-image: url(${BgLg});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media ${device.desktopXL} {
    background-image: url(${BgXl});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`
