import styled, { css } from 'styled-components'
import { white, grayscale, orange } from 'src/styles/colors'
import Check from 'inter-frontend-svgs/lib/v2/action/stats/success.svg'
import { device } from 'src/styles/breakpoints'

export const Steps = styled.nav`

  ul {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;

    li {
      width: 24px;
      height: 24px;
      color: ${grayscale[500]};
      font-size: 12px;
      background-color: ${white};
      border: 2px solid ${grayscale[500]};
      z-index: 1;
      border-radius: 100%;
      line-height: 20px;

      &.active {
        color: ${white};
        background-color: ${grayscale[500]};
      }

      &.selected {
        cursor: pointer;

        &::after {
          content: url(${Check});
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: block;
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
`

type OptionCardProps = {
  selected: boolean;
  color?: string;
}

export const OptionCard = styled.div<OptionCardProps>`
  border-radius: 8px;
  border: 2px solid ${grayscale[100]};
  position: relative;
  cursor: pointer;
  overflow: hidden;

  svg path {
    fill: ${grayscale[400]};
  }

  h5 {
    color: ${grayscale[500]};
  }

  ${(props: OptionCardProps) => (
    props.selected && css`
      border: 2px solid ${orange.extra};

      svg path {
        fill: ${orange.extra};
      }

      h5 {
        color: ${orange.extra};
      }
    `
  )}

  ${(props: OptionCardProps) => (
    props.color && css`

      @media ${device.tablet} {
        &::after {
          content: '';
          right: 0;
          top: 0;
          bottom: 0;
          width: 20px;
          position: absolute;
          background-color: ${props.color};
        }
      }
    `
  )}

`
