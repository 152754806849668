import React from 'react'
import { RadarPoint } from 'recharts'

const CustomXAxisTick = ({ x, y, payload }: RadarPoint) => {
  const year = payload.value
  return (
    <g>
      <text x={x + 20} y={y} dy={15} fontSize={12} textAnchor='end' fill='#6A6C72'>
        {year} {year > 1 ? 'anos' : 'ano'}
      </text>
    </g>
  )
}

export default CustomXAxisTick
