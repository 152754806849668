import React, { useEffect, useState } from 'react'
import { Utility } from 'inter-site-components'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Components
import OpenModal from 'src/components/OpenModal'
import CustomTooltip from './CustomTooltip'

// Types
import { Fund } from '../../types'
import CustomXAxisTick from './CustomXAxisTick'
import CustomYAxisTick from './CustomYAxisTick'

// Style
import { Wrapper } from './style'

type PeriodObject = {
  [param: number]: number[];
}

type DataChart = {
  period: number;
  investment: [number, number];
  value: number;
}

type ResultValues = {
  mode: string;
  incomeTax: number;
  initialContribution: number;
  monthlyContribution: number;
  period: number;
  taxation: string;
  fund: Fund;
}

type ResultChartProps = {
  values: ResultValues;
  setStep: (value: string) => void;
}

const ResultChart = ({ values, setStep }: ResultChartProps) => {
  const [ dataChart, setDataChart ] = useState<DataChart[]>()
  const [ sendDatalayerEvent ] = useDataLayer()
  const { mode, period, fund, initialContribution, monthlyContribution }: ResultValues = values
  const periodObject: PeriodObject = {
    10: [ 1, 2, 4, 6, 8, 10 ],
    15: [ 2, 4, 6, 8, 10, 15 ],
    20: [ 1, 3, 5, 10, 15, 20 ],
    25: [ 1, 5, 10, 15, 20, 25 ],
    30: [ 5, 10, 15, 20, 25, 30 ],
    35: [ 10, 15, 20, 25, 30, 35 ],
  }
  const color = '#FFBA00'
  let contentPgbl = <></>

  useEffect(() => {
    const months = period * 12
    const interestRate = Math.pow((1 + fund.rate), (1 / 12)) - 1 // Taxa de Juros (mensal)
    const baseAmount = initialContribution + (initialContribution * interestRate) // Montante base
    const amounts: number[] = [ baseAmount ]

    for (let index = 1; index <= months; index++) {
      const yearsValidate = months >= index // Validação Anos
      if (yearsValidate) {
        const beforeAmount = amounts[index - 1]
        const currentAmmount = ((beforeAmount + monthlyContribution) * interestRate) + (beforeAmount + monthlyContribution)
        amounts.push(currentAmmount)
      }
    }

    const formatedDataChart = periodObject[period].map((period: number) => ({
      period,
      investment: [ baseAmount, amounts[amounts.length - 1] ],
      value: amounts[period * 12],
    }))

    setDataChart(formatedDataChart as DataChart[])
  }, [ period, initialContribution, monthlyContribution ])

  if (mode === 'pgbl') {
    const deductibleAmount = values.incomeTax * 0.12
    const newBase = values.incomeTax - deductibleAmount
    contentPgbl = (
      <div className='text-left text-md-center my-4'>
        <h4 className='font-sora fw-600 fs-16 lh-20 text-grayscale--400 mb-2'>Valor dedutível do IR</h4>
        <p className='font-sora fw-600 fs-32 lh-40 text-orange--extra'>
          <small className='font-inter fw-400 fs-18 lh-22 text-grayscale--400 mr-1'>R$</small>
          {Utility.numberToReal(deductibleAmount)}
        </p>
        <p className='fs-14 lh-17 text-orange--extra'>Sua nova base de cálculo é: R$ {Utility.numberToReal(newBase)}</p>
      </div>
    )
  }

  return (
    <Wrapper>
      {contentPgbl}
      <ResponsiveContainer width='100%' height={340}>
        <LineChart data={dataChart} strokeWidth={0} margin={{ top: 20, right: 20, left: 0, bottom: 5 }}>
          <CartesianGrid vertical={false} strokeWidth={0.5} />
          <XAxis dataKey='period' domain={[ 'dataMin', 'auto' ]} tick={<CustomXAxisTick /> } />
          <YAxis dataKey='investment' domain={[ 'dataMin', 'auto' ]} tick={<CustomYAxisTick /> } />
          <Tooltip isAnimationActive={false} active content={<CustomTooltip fund={fund} />} />
          <Line
            dot
            activeDot={{ stroke: color, r: 6 }}
            type='monotone'
            dataKey='value'
            stroke={color}
            r={6}
          />
        </LineChart>
      </ResponsiveContainer>
      <div className='row justify-content-center mt-5'>
        <div className='col-12 col-lg-7 col-xl-5 text-center text-lg-left'>
          <p className='fs-16 lh-19 text-grayscale--400'>Os valores reais podem variar de acordo com o tempo de permanência no fundo, os valores aplicados e a rentabilidade alcançada. No cálculo de acumulação, foram desconsiderados impostos, tributações e taxas.</p>
        </div>
        <div className='col-12 col-lg-5 text-center text-lg-right'>
          <OpenModal to='open-your-account-form'>
            <button
              className='btn bg-orange--extra text-white text-none rounded-3 fw-600 fs-14 d-none d-lg-inline'
              title='Quero investir na minha previdência'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  element_name: 'Quero investir na minha previdência',
                  section_name: 'Veja os números dessas escolhas',
                })
              }}
            >
              Quero investir na minha previdência
            </button>
          </OpenModal>
          <a href='bancointer://investimentos' className='btn bg-orange--extra text-white text-none rounded-3 fw-600 fs-14 d-inline d-lg-none'>Quero investir na minha previdência</a>
          <button
            className='btn bg-transparent text-orange--extra text-none rounded-3 fw-600 fs-14 mt-4'
            title='Refazer escolhas'
            onClick={() => {
              setStep('choose-mode')
              sendDatalayerEvent({
                section: 'dobra_04',
                element_action: 'click button',
                element_name: 'Refazer escolhas',
                section_name: 'Veja os números dessas escolhas',
              })
            }}
          >
            Refazer escolhas
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default ResultChart
