import React from 'react'
import { CustomSection } from './style'
import { Link } from 'gatsby'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import headerJson from '../../assets/data/header.json'
import Home from '@interco/icons/build-v4/orangeds/MD/home'

import ScrollTo from 'src/components/ScrollTo'

import heroBanner from '../../assets/data/heroBanner.json'

const Hero = () => {
  return (
    <CustomSection className='pb-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 order-last order-md-first'>
            <div className='bread'>
              <Link
                to='/'
                className='d-md-inline'
              ><Home height={24} width={24} color='#6A6E81' />
              </Link>
              <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
              <Link
                to='/pra-voce/investimentos/'
                className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
              >
                {headerJson.breadcrumb[0]}
              </Link>
              <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
              <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
            </div>
            <h1 className='fw-600 pb-3 mb-0 text-grayscale--500'>{heroBanner.banner_title}</h1>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 pb-4 mb-0'>{heroBanner.banner_description}</p>
            <ScrollTo
              to='#calcule-seu-futuro'
              section='dobra_01'
              sectionName='A Previdência Privada que seu futuro precisa'
              elementName='Simular minha previdência'
              styles='w-100'
            >
              <button
                className='btn btn--lg bg-orange--extra my-2 text-white rounded-3 text-none fw-700 fs-14 lh-17'
              >
                {heroBanner.banner_cta}
              </button>
            </ScrollTo>
          </div>
          <div className='col-12 col-md-6 col-lg-5 col-xl-6 mb-4 mb-md-0 '>
            <img src={heroBanner.banner_image} alt={heroBanner.banner_title} />
          </div>
        </div>
      </div>
    </CustomSection>
  )
}

export default Hero
